import { getMasterWineName, vintageDisplayName } from '@helpers/vintage';
import { isPartnerWinery } from '@helpers/winery';
import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { ComponentSize } from '@webtypes/common';
import { BasicVintageWithBasicWine } from '@webtypes/goApi';
import cx from 'classnames';
import React from 'react';

import PartnerWineryBadge, {
  PartnerWineryBadgeSizes,
} from '@components/WineryPage/components/PartnerWineryBadge/PartnerWineryBadge';

import styles from './wineInfoVintage.scss';

interface WineInfoVintageProps {
  vintage: BasicVintageWithBasicWine;
  size?: ComponentSize;
  isMasterWineCard?: boolean;
  className?: string;
}

const WineInfoVintage = ({
  vintage,
  size = ComponentSize.Base,
  isMasterWineCard = false,
  className,
}: WineInfoVintageProps) => {
  const displayName = isMasterWineCard ? getMasterWineName(vintage) : vintageDisplayName(vintage);

  const winery = vintage?.wine?.winery;

  return (
    <div className={cx(styles.wineInfoVintage, styles[size], className)}>
      {winery && (
        <div className={styles.winery}>
          <Typography type={TypographyType.BodyMedium} className={styles.truncate}>
            {winery.name}
          </Typography>
          {isPartnerWinery(winery) && <PartnerWineryBadge size={PartnerWineryBadgeSizes.small} />}
        </div>
      )}
      <Typography type={TypographyType.LabelLarge} className={cx(styles.vintage, styles.truncate)}>
        {displayName}
      </Typography>
    </div>
  );
};

export default WineInfoVintage;
