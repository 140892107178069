import { isSSR } from '@helpers/ssr';
import { isDefaultVintage, vintageAltText } from '@helpers/vintage';
import { Link, LinkTheme, TypographyType, useBreakpoint } from '@vivino/js-react-common-ui';
import { ComponentSize } from '@webtypes/common';
import { VFMCategory } from '@webtypes/common';
import { BasicVintage, BasicVintageWithBasicWine } from '@webtypes/goApi';
import { RubyLibVintagePrice } from '@webtypes/rubyLibApi';
import cx from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { track } from 'vivino-js/analytics';
import { WebApiHighlight } from 'vivino-js/api/highlights';
import { CartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';
import { MerchandizingCampaignContext } from 'vivino-js/context/MerchandizingContext';
import { getCartItemSource, getCartItemSourceProps } from 'vivino-js/helpers/cartItemSources';
import { isPPCPrice } from 'vivino-js/helpers/price';
import { vintageUrl, wineUrl } from 'vivino-js/vivinoUrls';
import Card from 'vivino-ui/atoms/Card';
import DiscountSplash from 'vivino-ui/atoms/DiscountSplash';
import LabelShot from 'vivino-ui/atoms/LabelShot';

import BottleShot, { BottleSize } from '@components/BottleShot/BottleShot';
import VFMBadge from '@components/VFMScale/components/VFMBadge/VFMBadge';
import VFMScore from '@components/VFMScore/VFMScore';
import VivinoRating, { VivinoRatingSizes } from '@components/VivinoRating/VivinoRating';
import WineInfo from '@components/WineInfo/WineInfo';

import SuperWineCardSection from 'vivino-ui/components/SuperWineCardSection';
import { SuperWineCardOptions } from 'vivino-ui/components/SuperWineCardSection/SuperWineCardSection';

import AddToCart from '../AddToCart/AddToCart';
import styles from './wineCard.scss';

export interface WineCardPropTypes {
  className?: string;
  editorNote?: {
    note: string;
    user: Partial<{
      [key: string]: string;
    }>;
  };
  review?: {};
  isLoadingReview?: boolean;
  highlight?: WebApiHighlight;
  isLoadingHighlight?: boolean;
  blurb?: string;
  price?: RubyLibVintagePrice;
  vintage: BasicVintageWithBasicWine;
  showOutOfStock?: boolean;
  useLabelShots?: boolean;
  isMasterWineCard?: boolean;
  size?: ComponentSize;
  trackingProps?: { screen?: string; event: any; props?: {} };
  superWineCardOptions?: SuperWineCardOptions;
  onClick?: () => void;
  experiments?: any;
  showPriceForVintage?: boolean;
}

const WineCard = ({
  className,
  experiments,
  price,
  vintage,
  showOutOfStock = true,
  useLabelShots = false,
  isMasterWineCard = false,
  size = ComponentSize.Base,
  trackingProps,
  superWineCardOptions,
  onClick,
  showPriceForVintage = true,
}: WineCardPropTypes) => {
  const typeId = vintage?.wine?.type_id;
  const cartItemSourceType = useContext(CartItemSourceContext);
  const campaignId = useContext(MerchandizingCampaignContext);
  const { isMobile } = useBreakpoint();
  const vfmBadgeRef = useRef<HTMLDivElement>(null);
  const [vfmBadgeHeight, setVfmBadgeHeight] = useState(0);

  const { wine } = vintage;

  const vfmCategory = (price as any)?.vfm_category;
  const showVfMCategory = vfmCategory && vfmCategory.id > 0;

  useEffect(() => {
    if (trackingProps) {
      track(trackingProps);
    }
    if (
      !vfmBadgeRef.current ||
      !vfmCategory?.id ||
      vfmCategory.id !== VFMCategory.Amazing ||
      vfmCategory.title.length < 20
    )
      return;

    const resizeObserver = new ResizeObserver((entries) => {
      const [entry] = entries;
      if (entry) {
        setVfmBadgeHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(vfmBadgeRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [vfmCategory]);

  let vintageAnchorProps = {};
  let cartItemSourceProps = {};
  if (!isSSR) {
    const cartItemSourceData = getCartItemSource(cartItemSourceType, campaignId);
    cartItemSourceProps = getCartItemSourceProps(cartItemSourceData, vintage);
  }
  vintageAnchorProps = {
    rel: isMasterWineCard ? undefined : 'nofollow',
    href: isMasterWineCard ? wineUrl({ wine }) : vintageUrl({ vintage, price }),
    ...cartItemSourceProps,
  };

  const ratingProps = isMasterWineCard ? { wine } : { vintage };

  const addToCartProps = {
    price,
    seenVintageId: vintage.id,
    showOutOfStock,
    fullWidth: true,
    vintageYear: null,
    showPriceForVintage,
  };

  if (isMasterWineCard && !isDefaultVintage(vintage)) {
    addToCartProps.vintageYear = vintage.year;
  }

  // Render the large card only when size is Large and not mobile
  const finalSize =
    size === ComponentSize.Large && !isMobile ? ComponentSize.Large : ComponentSize.Base;

  const renderVfM = () => (
    <div className={styles.vfm_score}>
      <VFMScore category={vfmCategory} experiments={experiments} />
    </div>
  );

  const renderWineInfo = () => (
    <WineInfo vintage={vintage} isMasterWineCard={isMasterWineCard} vintageSize={finalSize} />
  );

  return (
    <div
      className={cx(className, styles.wineCard, {
        [styles.isSuperWineCard]: !!superWineCardOptions,
      })}
      data-testid="wineCard"
    >
      <Card className={styles.wineCardContent}>
        <Link
          theme={LinkTheme.Quiet}
          className={styles.cardLink}
          {...vintageAnchorProps}
          data-testid="vintagePageLink"
          onClick={onClick}
        >
          {superWineCardOptions && (
            <SuperWineCardSection
              size={finalSize}
              superWineCardOptions={superWineCardOptions}
              className={styles.superWineCard}
              withinWineCard
            />
          )}
          <div className={styles.topSection}>
            <div className={styles.bottleSection}>
              <DiscountSplash className={styles.splash} price={price} />
              {useLabelShots ? (
                <LabelShot
                  className={cx(styles.labelShot)}
                  vintage={vintage as BasicVintage}
                  alt={vintageAltText(vintage)}
                  small
                />
              ) : (
                <BottleShot
                  size={BottleSize.Small}
                  className={styles.bottleShot}
                  image={vintage.image}
                  typeId={typeId}
                  alt={vintageAltText(vintage)}
                />
              )}
            </div>
            <div className={styles.rateAndVfM}>
              <VivinoRating
                {...ratingProps}
                size={VivinoRatingSizes.Large}
                className={styles.rating}
                ratingCountOnly={true}
              />
              {showVfMCategory && !isPPCPrice(price) && renderVfM()}
            </div>
          </div>
          {showVfMCategory && !isPPCPrice(price) && vfmCategory?.id === VFMCategory.Amazing && (
            <div className={styles.vfmBadgeOnBottleContainer}>
              <div
                className={cx(styles.vfmBadgeOnBottle, {
                  [styles.vfmBadgeOnBottleTwoLines]: vfmBadgeHeight > 40,
                })}
                ref={vfmBadgeRef}
              >
                <VFMBadge
                  text={vfmCategory.title}
                  position={vfmCategory.barometer_position}
                  labelType={TypographyType.LabelMedium}
                  type="badgeOnBottle"
                />
              </div>
            </div>
          )}
          <div className={styles.grow}>{renderWineInfo()}</div>
          <AddToCart {...addToCartProps} />
        </Link>
      </Card>
    </div>
  );
};

export default WineCard;
