import { Alert, AlertTheme } from '@vivino/js-react-common-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { price } from 'vivino-js/apiPropTypes';
import { isPPCPrice, priceToString } from 'vivino-js/helpers/price';
import t from 'vivino-js/translationString';

import Price from '@components/Price/Price';

import AddToCartButton from '../AddToCartButton/AddToCartButton';
import styles from './addToCart.scss';

const TRANSLATIONS_PATH = 'components.shared.add_to_cart_button';
const TRANSLATIONS = {
  soldOut: `${TRANSLATIONS_PATH}.sold_out`,
  availableOnline: `${TRANSLATIONS_PATH}.available_online`,
  priceForTheYear: `${TRANSLATIONS_PATH}.price_for_the_year`,
  averagePrice: `${TRANSLATIONS_PATH}.average_price`,
};

const AddToCart = ({
  price,
  seenVintageId,
  vintageYear,
  showOutOfStock = true,
  className,
  fullWidth = false,
  showPriceForVintage = true,
}) => {
  const [warningMessage, setWarningMessage] = useState('');

  const handleError = (message) => {
    setWarningMessage(message);
  };

  if (!price) {
    return showOutOfStock ? (
      <div className={cx(styles.subText, styles.soldOut)}>{t(TRANSLATIONS.soldOut)}</div>
    ) : null;
  }

  if (isPPCPrice(price)) {
    return (
      <div className={styles.averagePriceContainer}>
        <p className={styles.price}>
          {priceToString({
            amount: price.amount,
            currency: price.currency,
          })}
        </p>
        <p className={styles.averagePrice}>{t(TRANSLATIONS.averagePrice)}</p>
      </div>
    );
  }

  return (
    <>
      <div className={styles.pricesContainer}>
        <div className={styles.priceContainerInner}>
          {!!price.discounted_from && (
            <Price
              strike
              amount={price.discounted_from}
              currency={price.currency}
              className={styles.originalPrice}
            />
          )}
          <Price amount={price.amount} currency={price.currency} className={styles.price} />
        </div>
      </div>

      {warningMessage && (
        <Alert subdued theme={AlertTheme.Critical} className={styles.warningMessage}>
          {warningMessage}
        </Alert>
      )}

      {price.message && <div className={styles.subText}>{price.message}</div>}
      {showPriceForVintage && vintageYear && (
        <div className={styles.subText}>
          {t(TRANSLATIONS.priceForTheYear, { year: vintageYear })}
        </div>
      )}

      <div className={styles.buttonContainer}>
        <AddToCartButton
          price={price}
          seenVintageId={seenVintageId}
          onError={handleError}
          disabled={!!warningMessage}
          className={cx(styles.addToCartButton, className)}
          fullWidth={fullWidth}
        />
      </div>
    </>
  );
};

AddToCart.propTypes = {
  price: price,
  seenVintageId: PropTypes.number.isRequired,
  vintageYear: PropTypes.number,
  showOutOfStock: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  showPriceForVintage: PropTypes.bool,
};

export default AddToCart;
