import { ComponentSize } from '@webtypes/common';
import { BasicVintageWithBasicWine } from '@webtypes/goApi';
import cx from 'classnames';
import React from 'react';

import WineInfoVintage from '@components/WineInfoVintage/WineInfoVintage';

import styles from './wineInfo.scss';

interface WineInfoProps {
  vintage: BasicVintageWithBasicWine;
  vintageSize?: ComponentSize;
  locationSize?: ComponentSize;
  isMasterWineCard?: boolean;
}

const WineInfo = ({
  vintage,
  vintageSize = ComponentSize.Base,
  locationSize = ComponentSize.Base,
  isMasterWineCard = false,
}: WineInfoProps) => (
  <div className={cx(styles.wineInfo)}>
    <WineInfoVintage
      vintage={vintage}
      size={vintageSize}
      className={styles.vintage}
      isMasterWineCard={isMasterWineCard}
    />
  </div>
);

export default WineInfo;
