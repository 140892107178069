import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { VivinoPropTypes, requireOneOf } from 'vivino-js/apiPropTypes';
import {
  formatRating,
  getAverageAndCount,
  getNotEnoughRatingsText,
  getRatingText,
  showRatingFor,
} from 'vivino-js/helpers/vivinoRating';
import { VivinoRatingType } from 'vivino-js/helpers/vivinoRating';

import Rating, { RatingSize, RatingType } from '../Rating/Rating';
import styles from './vivinoRating.scss';

export const VivinoRatingSizes = {
  Normal: 'normal',
  Large: 'large',
};

const EmptyRating = ({ size = VivinoRatingSizes.Normal, className }) => (
  <div className={cx(styles.vivinoRating, styles[size], className)}>
    <div className={styles.starsAndCaption}>
      <div className={styles.stars}>
        <Rating theme={RatingType.Vivino} size={RatingSize.MEDIUM} rating={0} />
      </div>
      <div className={styles.caption}>{getNotEnoughRatingsText()}</div>
    </div>
  </div>
);
EmptyRating.propTypes = {
  size: PropTypes.oneOf(Object.values(VivinoRatingSizes)),
  className: PropTypes.string,
};

const VivinoRating = ({
  vintage = null,
  wine = null,
  size = VivinoRatingSizes.Normal,
  className,
  ratingCountOnly = false,
}) => {
  const [ratingType, subject] = vintage
    ? showRatingFor({ vintage })
    : [VivinoRatingType.Wine, wine];

  if (!subject?.has_valid_ratings) {
    return <EmptyRating size={size} className={className} />;
  }

  const [ratingsAverage, count] = getAverageAndCount(subject?.statistics);

  const ratingText = getRatingText({ ratingType, count });
  const formattedCount = count.toLocaleString();

  return (
    <div className={cx(styles.vivinoRating, styles[size], className)}>
      <div className={styles.averageValue}>{formatRating(ratingsAverage)}</div>
      <div className={styles.starsAndCaption}>
        <div className={styles.stars}>
          <Rating theme={RatingType.Vivino} size={RatingSize.MEDIUM} rating={ratingsAverage} />
        </div>
        <div className={styles.caption}>({ratingCountOnly ? formattedCount : ratingText})</div>
      </div>
    </div>
  );
};

VivinoRating.propTypes = {
  ...requireOneOf({
    vintage: VivinoPropTypes.vintage,
    wine: VivinoPropTypes.wine,
  }),
  size: PropTypes.oneOf(Object.values(VivinoRatingSizes)),
  className: PropTypes.string,
  ratingCountOnly: PropTypes.bool,
};

export default VivinoRating;
